<template>
  <div>
    <BaseDialog :visible.sync="currentVisible" :showFooter="false" title='预览' >
    <div class="dowlaodbtn">
      <base-button icon="iconfont iconxiazai" label="文件下载" @click="dowload()"></base-button></div>
    <!-- <pre-view :fileId="fileData.fileId&&fileData.fileId!=='0'?fileData.fileId:fileData.fileId" :fileType="fileData.fileId&&fileData.fileId!=='0'?'pdf':fileData.suffix" :count="count"></pre-view> -->
    <pre-view :fileId="fileData.currentFileId" :fileType="fileData.currentFileFileSuffix" :count="count"></pre-view>
     </BaseDialog>
  </div>
</template>

<script>
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import PreView from '@/components/pre-view/pre-view.vue'
export default {
  components: { BaseDialog, PreView, BaseButton },
  props: {
    visible: Boolean,
    fileData: Object
  },
  data () {
    return {
      count: 1
    }
  },
  watch: {
    'fileData.fileId': {
      handler (val) {
        this.$nextTick(() => {
          this.count++
        })
      }
    }
  },
  computed: {
    currentVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    dowload () {
      this.$emit('dowload', this.fileData)
    },
    closed () {

    }
  }
}
</script>

<style lang="scss" scoped>
.dowlaodbtn{
  padding: 5px 10px;
  text-align: right;
}
</style>
